<template>
  <layout class="main-cols">
    <div
      class="columns is-multiline h-full items-center flex-row justify-center h-85vh"
    >
      <div class="column is-6">
        <b-image class="is-6" src="/images/error403.png" alt="" />
      </div>
      <div class="column is-12 mt-8">
        <p class="has-text-primary text-4xl font-bold">
          {{ $route.params.data.message }}
        </p>
        <p class="has-text-weight-bold">Ruta: {{ $route.params.config.url }}</p>
        <p>
          Si crees que esto es un error, contacta a tu administrador o
          administradores del sistema:
        </p>
        <p class="has-text-weight-bold">
          {{ administradores }}
        </p>
      </div>
      <div class="column is-4">
        <b-button type="is-primary" expanded @click="$router.back(1)">
          Volver</b-button
        >
      </div>
    </div>
  </layout>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Error403",
  mounted() {
    if (this.getSysAdmins == null) {
      this.$store.dispatch("getSysAdmins");
    }
  },
  computed: {
    ...mapGetters(["getSysAdmins"]),
    administradores() {
      if(this.getSysAdmins){
        return this.getSysAdmins.join(", ");
      }else{
        return "";
      }
    }
  }
};
</script>
